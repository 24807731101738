<template>
    <PageLayout>
        <template>

                <div class="d-flex w-100 justify-space-between mb-6">
                    <div class="d-flex align-center" >
                        <h1><span class="cursor-pointer" @click="$router.push({name: 'dashboard'})">{{ $t('dashboard') }}</span> / {{dashboard_name}} </h1>
                    </div>
                    <div class="text-right">

                        <Btn
                            v-if="$vuetify.breakpoint.smAndUp"
                            @click="dialogAddWidget=true"
                            color="secondary"
                            :title="$t('widget_creation')"
                        >
                            {{ $t('widget_creation') }}
                        </Btn>
                        <Teleport to="#app" v-else>
                            <div class="fab__wrapper">
                                <Btn
                                    fab
                                    @click="dialogAddWidget=true"
                                    :title="$t('widget_creation')"
                                    color="secondary"
                                ><v-icon>mdi-plus</v-icon></Btn>
                            </div>
                        </Teleport>
                    </div>
                </div>



                <chart-display :key="updateChartDisplay" v-if="widgetItems.length > 0" :widgets="widgetItems" dashboard = "2" border="10" />



            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="dialogAddWidget"
            >
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card>
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('widget_creation') }}</span>
                            </div>
                            <div>
                                <v-icon @click="closeAddWidget()">mdi-close</v-icon>
                            </div>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="widget_template">
                                        <SelectInput
                                        clearable
                                        item-text="name"
                                        item-value="url"
                                        hide-details
                                        hide-label
                                        :items="widgetTemplates"
                                        v-model="widget_template"
                                        name="widget_template"
                                        id="widget_template"
                                        :hideLabel="false"
                                        :label="$t('widget_type')"
                                        @input="setWidgetName()"
                                        ></SelectInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0 pt-5" cols="12" sm="12" v-if="false">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="chart">
                                        <SelectInput
                                            clearable
                                            item-text="title"
                                            item-value="value"
                                            hide-details
                                            hide-label
                                            :items="chartItems"
                                            v-model="chart"
                                            name="chart"
                                            id="chart"
                                            :hideLabel="false"
                                            :label="$t('chart_name')"
                                        ></SelectInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0 pt-5" cols="12" sm="12" >
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="coll">
                                        <SelectInput
                                            clearable
                                            item-text="title"
                                            item-value="value"
                                            hide-details
                                            hide-label
                                            :items="collItems"
                                            v-model="coll"
                                            name="coll"
                                            id="coll"
                                            :hideLabel="false"
                                            :label="$t('coll')"
                                        ></SelectInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0 pt-5" cols="12" sm="12" v-if="false">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="widget_name">
                                        <TextInput
                                            id="widget_name"
                                            :label="$t('widget_name')"
                                            :error="!valid"
                                            :error-messages="errors"
                                            v-model="widget_name"
                                            require
                                        ></TextInput>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-btn :disabled="invalid || loading" :loading="loading" color="primary"
                                           @click="AddWidget()"
                                           class="infinity_button">
                                        {{ $t('add') }}
                                    </v-btn>
                                    <v-btn
                                        :disabled="loading"
                                        class="ma-1 button_cancel"
                                        plain
                                        @click="closeAddWidget()"
                                    >
                                        {{ $t('cancel') }}
                                    </v-btn>
                                    <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                                    <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                                       :active="loading" class="mx-2"></v-progress-linear>
                                </v-col>
                            </v-row>

                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-dialog>

        </template>
    </PageLayout>
</template>
<script>


import {mapGetters} from "vuex";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import PageLayout from "@/components/Leentech/PageLayout.vue";
import Btn from "@/components/Form/Btn.vue";
import TextInput from "@/components/Form/TextInput";
import ChartDisplay from "@/components/ChartDisplay";
import SelectInput from "@/components/Form/SelectInput.vue";

export default {
    name: "DashboardShow",
    components: {
        PageLayout,
        Btn,
        ValidationObserver,
        ValidationProvider,
        TextInput,
        ChartDisplay,
        SelectInput,
    },
    data() {
        return {
            progress: 0,
            loading: false,
            dashboard_name: null,
            widget_name: null,
            dashboard_id: null,
            dialogAddWidget: false,
            widgetItems: [],
            widgetCollectItems: [],

            updateChartDisplay: 0,
            widget_template: null,
            widgetTemplates: [
                {
                    id: 1,
                    name: 'Тип клиентов',
                    chart_type: 'pie',
                    coll: 4,
                    url: '?group=type_name&table=document&fields=type_name&aggregate_function[files]=count&chart=pie',
                    listItems: []
                },
                {
                    id: 2,
                    name: 'Тип клиентов',
                    chart_type: 'doughnut',
                    coll: 4,
                    url: '?chart=bar&group=type_client_name&table=uved&fields=type_client_name&aggregate_function[type_client_name]=count',
                    listItems: []
                },
                {
                    id: Math.floor(Math.random() * (99999 - 10000)) + 10000,
                    name: 'Мои задачи',
                    chart_type: 'table_one_column',
                    coll: 4,
                    url: '?table=task&fields=task_status_name&filter[admin_id][eq]=2&group=task_status_name&aggregate_function[task_status_name]=count&filter_update_1725530899=1725530899&chart=table',
                    listItems: []
                },
                {
                    id: 3,
                    name: 'Задачи по сотрудникам',
                    chart_type: 'horizontal_bar',
                    coll: 12,
                    url: '?chart=horizontal_bar&group=name&table=task&fields=name&aggregate_function[name]=count',
                    listItems: []
                },
                {
                    id: Math.floor(Math.random() * (99999 - 10000)) + 10000,
                    name: 'Контрагенты',
                    chart_type: 'table',
                    coll: 12,
                    url: '?table=uved&fields=company_name,phones,emails,addresses,last_comment_date',
                    listItems: []
                },
                {
                    id: Math.floor(Math.random() * (99999 - 10000)) + 10000,
                    name: 'Мои задачи в работе',
                    chart_type: 'block_count',
                    coll: 12,
                    url: '?table=task&fields=task_status_name&filter[admin_id][eq]=2&filter[task_status_id][eq]=2&group=task_status_name&aggregate_function[task_status_name]=count&filter_update_1725535652=1725535652&chart=table',
                    listItems: []
                }
            ],
            chart: null,
            chartItems: [
                { value: 'pie', title: this.$t('pie') },
                { value: 'line', title: this.$t('line') },
                { value: 'bar', title: this.$t('bar') },
                { value: 'doughnut', title:  this.$t('doughnut') },
                { value: 'horizontal_bar', title:  this.$t('horizontal_bar') },
                { value: 'table', title:  this.$t('table') },
                { value: 'table_one_column', title:  this.$t('table_one_column') },
                { value: 'block_count', title:  this.$t('block_count') },
            ],

            coll: null,
            collItems: [
                { value: 12, title: this.$t('На всю строчку') },
                { value: 6, title: this.$t('1/2') },
                { value: 4, title: this.$t('1/3') },
                { value: 8, title: this.$t('1/1.5') },
            ],





        }
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
    },
    mounted() {
        this.getDashboard();
        this.getWidgetTemplates();
    },
    methods: {

        async getDashboard() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/dashboard/${this.$route.params.uuid}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.dashboard_id = res.body.data.id
                    this.dashboard_name = res.body.data.name
                    this.widgetCollectItems = res.body.data.widgets
                    this.loadAllWidgetData();
                    this.updateChartDisplay++;
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_dashboard'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async getWidgetTemplates() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/widget_template`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.widgetTemplates = res.body.data

                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_widget_templates'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async loadAllWidgetData() {
            this.loading = true;

            try {
                for (const [x, item] of this.widgetCollectItems.entries()) {
                    await this.getReportTable(item, x);
                }
                this.widgetItems = this.widgetCollectItems;
                this.updateChartDisplay++;
            } catch (err) {
                this.$toastr.error(this.$t('failed_to_get_list_widget'));
            } finally {
                this.loading = false;
            }
        },
        async getReportTable(item, x) {
            this.loading = true;


            let params = {};

            params.language = this.lang;
            params.table = 'horizontal_bar';

            if(params.table &&  params.table!== ''){
                await this.$http
                    .get(`admin/report_data${item.url}`, {
                        params: item.url,
                    })
                    .then(res => {
                        this.widgetCollectItems[x].listItems = JSON.stringify(res.body.data)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_list_widget'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }

        },


        setWidgetName(){
            const selectedItem = this.widgetTemplates.find(item => item.url === this.widget_template);
            if (selectedItem) {
                this.widget_name = selectedItem.name
                this.chart = selectedItem.chart_type
            }

        },

        closeAddWidget(){
            this.dialogAddWidget = false;
            this.widget_name = null;
            this.widget_template = null;
            this.chart = null;
            this.coll = null;
        },

        async AddWidget() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()



            if (this.$route.params.uuid) {
                formData.append('dashboard_uuid', this.$route.params.uuid)
            }

            if (this.widget_name) {
                formData.append('widget_name', this.widget_name)
            }
            if (this.widget_template) {
                formData.append('url', this.widget_template)
            }
            if (this.chart) {
                formData.append('chart_type', this.chart)
            }
            if (this.coll) {
                formData.append('coll', this.coll)
            }
            // Add
            await this.$http
                .post('admin/widget', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('widget_has_been_added'))
                    this.closeAddWidget();
                    this.getDashboard();
                    this.updateChartDisplay++;
                })
                .catch(err => {
                    this.$toastr.error(this.$t('widget_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },

    }
}
</script>
